import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { Icon, IconVariant } from '@/components/Icon';
import { Layout, LayoutVariant } from '@/components/Layout';
import LinkWrapper from '@/components/LinkWrapper';
import Page from '@/components/Page';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';

export default class Page404 extends Page<{
  title: string;
  path: string;
}> {
  render() {
    const { title, path } = this.props;
    return (
      <>
        <Header />
        <main className="page page--default">
          <Layout path={path} variant={LayoutVariant.Home} theme="home">
            <div className="Page404Content">
              <img
                className="Page404Content__image"
                src="/assets/images/404.svg"
                width="648"
                height="395"
                alt=""
              />
              <div className="Page404Content__content">
                <h1 className="headline">
                  Die von Ihnen gesuchte Seite haben wir nicht gefunden.
                </h1>
                <div
                  className="Page404Content__description"
                  dangerouslySetInnerHTML={replaceAbbreviations(
                    'Möglicherweise existiert die Seite nicht oder die URL ist veraltet/fehlerhaft. Über den folgenden Button gelangen Sie zur Startseite von LOTTO.de. Sollten Sie weiterhin Probleme haben, finden  Sie im <LinkWrapper href="/impressum">Impressum</LinkWrapper> einen E-Mail Kontakt, an den Sie sich wenden können.'
                  )}
                />
                <LinkWrapper href="/">
                  <Button
                    fullWidth={true}
                    iconPosition={Button.IconPosition.Right}
                    variant={ButtonVariant.PrimaryIconRight}
                    className="Page404Content__button"
                  >
                    Startseite von LOTTO.de
                    <Icon variant={IconVariant.ArrowRight} />
                  </Button>
                </LinkWrapper>
              </div>
            </div>
          </Layout>
          <Footer />
        </main>
      </>
    );
  }
}

export async function getStaticProps() {
  return {
    props: {
      path: '/404',
      theme: 'default',
      description:
        'Möglicherweise existiert die Seite nicht oder die URL ist veraltet/fehlerhaft.',
      title: '404 - Die von Ihnen gesuchte Seite haben wir nicht gefunden.',
    },
  };
}
